import {Module} from "@/app/editor/module";
import {DefineComponent, UnwrapRef} from "vue";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";
import IntroductionPreview from "@/app/editor/module/introduction/v1/IntroductionPreview.vue";
import IntroductionSetup from "@/app/editor/module/introduction/v1/IntroductionSetup.vue";

export interface IIntroductionConfig {
    notice: string[]
    shop_style: number
    shop_content: string
    content_images: string[]
}

export interface IActivityIntroductionContent {
    content: string
}

interface IIntroductionAttach {
    introduction_contents: IActivityIntroductionContent[]
}

export class Introduction extends Module {
    cfg: UnwrapRef<IIntroductionConfig>;
    attach: IIntroductionAttach

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '内容设置', 'jie_shao', IntroductionPreview as DefineComponent, IntroductionSetup as DefineComponent);
        this.cfg = m.config as UnwrapRef<IIntroductionConfig>
        this.attach = m.attach as IIntroductionAttach
    }
}