import {Module} from "@/app/editor/module";
import {DefineComponent, UnwrapRef, watch} from "vue";
import {Activity} from "@/app/editor/activity";
import {IActivityGift} from "@/app/editor/material/gift";
import {IModuleData} from "@/lib/activity";
import ForwardPreview from "@/app/editor/module/forward/v1/ForwardPreview.vue";
import ForwardSetup from "@/app/editor/module/forward/v1/ForwardSetup.vue";

export interface IShareLimit {
    enable: boolean
    amount: number
}

export interface IForwardConfig {
    share_timeline_limit: IShareLimit
    timeline_like_limit: IShareLimit
    share_local_group_limit: IShareLimit
    complete_count: number
    gifts: IActivityGift[]
    content: string
}

export class Forward extends Module {
    cfg: UnwrapRef<IForwardConfig>;

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '转发奖励', 'fen_xiang', ForwardPreview as DefineComponent, ForwardSetup as DefineComponent);
        this.cfg = m.config as IForwardConfig
        watch(this.cfg.share_timeline_limit, () => this.syncContent())
        watch(this.cfg.timeline_like_limit, () => this.syncContent())
        watch(this.cfg.share_local_group_limit, () => this.syncContent())
        watch(() => this.cfg.complete_count, () => this.syncContent())
        this.syncContent()
    }

    syncContent() {
        this.cfg.content = (this.cfg.share_local_group_limit.enable ? `转发到 ${this.cfg.share_local_group_limit.amount}个本地生活群；` : '') +
            (this.cfg.share_timeline_limit.enable ? `连续 ${this.cfg.share_timeline_limit.amount} 天分享朋友圈；` : '') +
            (this.cfg.timeline_like_limit.enable ? `分享到朋友圈，集齐 ${this.cfg.timeline_like_limit.amount}赞；` : '') +
            `完成任意${this.cfg.complete_count}项，即可得：`
    }
}