import request from "@/lib/request";
import {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, Method} from "axios";
import {createFromIconfontCN} from "@ant-design/icons-vue";
import {UploadFile} from "ant-design-vue";

export type Json = { [key: string]: unknown }
export const IconFont = createFromIconfontCN({scriptUrl: require('@/assets/font/iconfont.js')})

export interface IReqRequest<D = any> {
    url: string
    data?: D
    params?: D
    header?: AxiosRequestHeaders
    config?: Json | undefined
    method?: Method | string
    success?: (res: any) => void
    fail?: (err: Error) => void
    complete?: () => void
}

export const req = (req: IReqRequest): void => {
    if ((req.method == 'get' || req.method == 'GET' || req.method == undefined) && req.data) {
        req.url += '?' + new URLSearchParams(req.data).toString()
        req.data = undefined
    }
    request.request<unknown, AxiosResponse, unknown>({
        url: req.url,
        params: req.params,
        method: req.method,
        data: req.data,
        ...req.config
    } as AxiosRequestConfig).then(rs => {
        req.success?.(rs)
    }).catch(err => {
        req.fail?.(err)
    }).finally(() => {
        req.complete?.()
    })
}

export const reqAsync = (options: IReqRequest): Promise<any> => {
    return new Promise((resolve, reject) => {
        options.success = resolve
        options.fail = reject
        req(options)
    })
}

export const printWelcome = (): void => {
    // ASCII - ANSI Shadow
    const text = `

                                                                                                                                                                                                      
                                                                                                                                                                                                      
HHHHHHHHH     HHHHHHHHH                                        DDDDDDDDDDDDD                                                                    BBBBBBBBBBBBBBBBB                                     
H:::::::H     H:::::::H                                        D::::::::::::DDD                                                                 B::::::::::::::::B                                    
H:::::::H     H:::::::H                                        D:::::::::::::::DD                                                               B::::::BBBBBB:::::B                                   
HH::::::H     H::::::HH                                        DDD:::::DDDDD:::::D                                                              BB:::::B     B:::::B                                  
  H:::::H     H:::::H  uuuuuu    uuuuuu     ooooooooooo          D:::::D    D:::::D    ooooooooooo   nnnn  nnnnnnnn       ggggggggg   ggggg       B::::B     B:::::B  aaaaaaaaaaaaa     ooooooooooo   
  H:::::H     H:::::H  u::::u    u::::u   oo:::::::::::oo        D:::::D     D:::::D oo:::::::::::oo n:::nn::::::::nn    g:::::::::ggg::::g       B::::B     B:::::B  a::::::::::::a  oo:::::::::::oo 
  H::::::HHHHH::::::H  u::::u    u::::u  o:::::::::::::::o       D:::::D     D:::::Do:::::::::::::::on::::::::::::::nn  g:::::::::::::::::g       B::::BBBBBB:::::B   aaaaaaaaa:::::ao:::::::::::::::o
  H:::::::::::::::::H  u::::u    u::::u  o:::::ooooo:::::o       D:::::D     D:::::Do:::::ooooo:::::onn:::::::::::::::ng::::::ggggg::::::gg       B:::::::::::::BB             a::::ao:::::ooooo:::::o
  H:::::::::::::::::H  u::::u    u::::u  o::::o     o::::o       D:::::D     D:::::Do::::o     o::::o  n:::::nnnn:::::ng:::::g     g:::::g        B::::BBBBBB:::::B     aaaaaaa:::::ao::::o     o::::o
  H::::::HHHHH::::::H  u::::u    u::::u  o::::o     o::::o       D:::::D     D:::::Do::::o     o::::o  n::::n    n::::ng:::::g     g:::::g        B::::B     B:::::B  aa::::::::::::ao::::o     o::::o
  H:::::H     H:::::H  u::::u    u::::u  o::::o     o::::o       D:::::D     D:::::Do::::o     o::::o  n::::n    n::::ng:::::g     g:::::g        B::::B     B:::::B a::::aaaa::::::ao::::o     o::::o
  H:::::H     H:::::H  u:::::uuuu:::::u  o::::o     o::::o       D:::::D    D:::::D o::::o     o::::o  n::::n    n::::ng::::::g    g:::::g        B::::B     B:::::Ba::::a    a:::::ao::::o     o::::o
HH::::::H     H::::::HHu:::::::::::::::uuo:::::ooooo:::::o     DDD:::::DDDDD:::::D  o:::::ooooo:::::o  n::::n    n::::ng:::::::ggggg:::::g      BB:::::BBBBBB::::::Ba::::a    a:::::ao:::::ooooo:::::o
H:::::::H     H:::::::H u:::::::::::::::uo:::::::::::::::o     D:::::::::::::::DD   o:::::::::::::::o  n::::n    n::::n g::::::::::::::::g      B:::::::::::::::::B a:::::aaaa::::::ao:::::::::::::::o
H:::::::H     H:::::::H  uu::::::::uu:::u oo:::::::::::oo      D::::::::::::DDD      oo:::::::::::oo   n::::n    n::::n  gg::::::::::::::g      B::::::::::::::::B   a::::::::::aa:::aoo:::::::::::oo 
HHHHHHHHH     HHHHHHHHH    uuuuuuuu  uuuu   ooooooooooo        DDDDDDDDDDDDD           ooooooooooo     nnnnnn    nnnnnn    gggggggg::::::g      BBBBBBBBBBBBBBBBB     aaaaaaaaaa  aaaa  ooooooooooo   
                                                                                                                                   g:::::g                                                            
                                                                                                                       gggggg      g:::::g                                                            
                                                                                                                       g:::::gg   gg:::::g                                                            
                                                                                                                        g::::::ggg:::::::g                                                            
                                                                                                                         gg:::::::::::::g                                                             
                                                                                                                           ggg::::::ggg                                                               
                                                                                                                              gggggg                                                                  

guandb.cn/${process.env.BRANCH}-${process.env.VERSION} ${process.env.LAST_COMMIT_DATETIME}`
    console.log(`%c${text}`, 'color: #fc4d50')
}

export const mock = (rUrl: string, rType: string, data: any) => {
    // if (process.env.NODE_ENV !== 'production') {
    //     mockjs.mock('https://api.activity.djhdb.cn/pc/' + rUrl, rType, data)
    //     mockjs.mock(RegExp(rUrl + '\\?.*'), rType, data)
    // }
}

export const mockArr = (rUrl: string, rType: string, min: number, max: number, data: unknown) => {
    // const obj: { [key: string]: unknown } = {}
    // obj[`arr|${min}-${max}`] = [data]
    // mock(rUrl, rType, () => mockjs.mock(obj).arr)
}

export const setupMock = () => {
    // if (process.env.NODE_ENV !== 'production') setup({timeout: '10-999'})
}

export function random (length: number): string {
    let str = Math.random().toString(36).slice(2)
    if (str.length >= length) return str.slice(0, length)
    str += random(length - str.length)
    return str
}

export const fileResize = (f: UploadFile): Promise<File> => {
    return new Promise((resolve, reject) => {
        if (f.size! < 1024 * 1024) {
            resolve(f as unknown as File)
            return
        }
        const reader = new FileReader();
        const img = new Image();
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        img.onload = () => {
            const h = (img.height / img.width) * 1080
            console.log(h)
            canvas.width = 1080
            canvas.height = h
            ctx!.clearRect(0, 0, canvas.width, canvas.height)
            ctx!.drawImage(img, 0, 0, canvas.width, canvas.height)
            canvas.toBlob(b => {
                if (b) resolve(new File([b!], f.name, {type: f.type}))
                else reject('缩略图转换失败')
            }, f.type)
        }
        img.onerror = () => reject('原图加载失败')
        reader.onload = (ev) => typeof ev.target?.result === "string" ? img.src = ev.target.result : null
        reader.readAsDataURL(f as unknown as File);
        reader.onerror = () => reject('文件读取失败')
    })
}

export function hash(s: string): number {
    return s.split('').reduce((a, b) => {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a
    }, 0)
}