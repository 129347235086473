import {reactive, UnwrapNestedRefs, watch} from "vue";
import {Module} from "@/app/editor/module";
import {mm} from "@/app/editor/module/module_manager";
import {message} from "ant-design-vue";
import {Json} from "@/lib/util";
import dayjs from "dayjs";
import emit from "@/lib/emit";
import {IActivityData, IActivityStatus, IThemeImageValue} from "@/lib/activity";

export class Activity {
    readonly data: UnwrapNestedRefs<IActivityData>
    private currentCode: number
    readonly modules: Module[]
    readonly publicVariables = ['预约热线', '店名', '拼团价', '拼团类型', '商品价值', '分享人昵称', '分享人头像']
    readonly status: UnwrapNestedRefs<IActivityStatus>

    constructor(data: IActivityData) {
        if (!data.activity_id) {
            // 创建
            data.start_at = new Date().toISOString()
            data.end_at = dayjs().add(7, 'day').toDate().toISOString()
        }
        this.data = reactive(data);
        this.status = reactive({
            date_range: [dayjs(), dayjs().add(7, 'days')],
            is_distribution: false,
            buy_button_text: '立即抢购',
            sell_price: 0,
            rules: [],
            shop_name: '',
            theme_poster_id: '',
            theme_thumb_version: 0,
        })
        watch(this.data, () => {
            if (this.currentCode != this.code()) {
                this.currentCode = this.code()
                emit.emit('activity.code.change', this.currentCode)
            }
        })

        this.modules = this.data.modules.map(m => {
            try {
                return mm.gen(this, m)
            } catch (e) {
                console.error(e)
                throw e
            }
        })
        // code 必须要在modules 之后！！
        this.currentCode = this.code()
    }

    variable(name: string): string | number | null {
        switch (name) {
            case '分享人昵称':
                return '顾客微信昵称';
            case '分享人头像':
                return 'https://b.s.mywsy.cn/media/image/default_header.jpg'
            default:
                for (const m of this.modules) {
                    const v = m.variable(name)
                    if (v !== null) return v
                }
        }
        return null
    }


    validate(): boolean {
        if (!this.data.start_at) {
            message.error({content: '活动开始时间未设置'})
            return false
        }
        if (!this.data.end_at) {
            message.error({content: '活动结束时间未设置'})
            return false
        }
        if (this.data.end_at && this.data.start_at && new Date(this.data.end_at).valueOf() < new Date(this.data.start_at).valueOf()) {
            message.error({content: '活动结束时间不正确'})
            return false
        }
        if (!this.data.sid || this.data.sid <= 0) {
            message.error({content: '活动门店未设置'})
            return false
        }
        return true
    }


    code(): number {
        // console.log('code:', this.publicVariables.map(k => this.variable(k)), this.publicVariables.map(k => this.variable(k)).join('') + this.data.title)
        return this.hash(this.publicVariables.map(k => this.variable(k)).join('') + this.data.title)
    }

    hash(s: string): number {
        return s.split('').reduce((a, b) => {
            a = ((a << 5) - a) + b.charCodeAt(0);
            return a & a
        }, 0)
    }

    genImageTemplateData(): Json {
        const o = {
            modules: this.data.modules,
            wx: {me: {head_img_url: '', nick_name: ''}}
        }
        this.data.modules.forEach(m => {
            if (m.module == 'shop') {
                o.wx.me.head_img_url = 'https://b.s.mywsy.cn/media/image/default_header.jpg'
                o.wx.me.nick_name = '微信昵称'
            }
        })
        return o
    }

    onThemeImageValueChange(iv: IThemeImageValue) {
        for (const ivk in this.data.theme_image_values) {
            for (const ivi in this.data.theme_image_values[ivk]) {
                if (this.data.theme_image_values[ivk][ivi].title == iv.title) this.data.theme_image_values[ivk][ivi].value = iv.value
            }
        }
    }
}