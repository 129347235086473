import {IActivityGift} from "@/app/editor/material/gift";
import {Module} from "@/app/editor/module";
import {DefineComponent, UnwrapRef, watch} from "vue";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";
import GroupPreview from "@/app/editor/module/group/v1/GroupPreview.vue";
import GroupSetup from "@/app/editor/module/group/v1/GroupSetup.vue";

export interface IGroupConfigStepItem {
    category: '组团优惠' | '全员福利' | '团长福利' | '未成团优惠'
    gift: IActivityGift | null
    title: string
    value: number
    amount: number
}

export interface IGroupStep {
    group_size: number
    price: number
    total_value: number
    items: IGroupConfigStepItem[]
}

export interface IGroupGoodsInfo {
    stock: number
    sell_price: number
    total_value: number
    leader_free: boolean
}

export interface IGroupModuleProps {
    buy_bottom_text: string
    customer_buy_limit: number
    goods_validity_mons: number
    is_only_regular_customer_can_be_leader: boolean
    is_only_freshman_can_be_join: boolean
    group_time_limit_hours: number
    group_info_picture: string[]
}

export interface IGroupConfig {
    type: '普通团' | '价格阶梯团' | '商品阶梯团'
    goods: IGroupGoodsInfo
    fail_items: IGroupConfigStepItem[]
    steps: IGroupStep[]
    props: IGroupModuleProps
}
const categories = ['组团优惠', '全员福利', '团长福利']
export class Group extends Module {
    cfg: UnwrapRef<IGroupConfig>;

    constructor(a: Activity, m: UnwrapRef<IModuleData>) {
        super(a, m, '团购设置', 'fuwu', GroupPreview as DefineComponent, GroupSetup as DefineComponent);
        this.cfg = m.config as UnwrapRef<IGroupConfig>

        // 自动更正数据
        if (!this.cfg.type) this.cfg.type = '普通团'
        if (!this.cfg.goods) this.cfg.goods = {
            sell_price: 0,
            total_value: 0,
            stock: 0,
            leader_free: false
        }
        if (!this.cfg.goods.total_value) this.cfg.goods.total_value = 0
        if (!this.cfg.steps || this.cfg.steps.length == 0) this.cfg.steps = [{
            group_size: 2,
            price: 0,
            items: [],
            total_value: 0
        }]
        if (!this.cfg.fail_items) this.cfg.fail_items = []
        for (let i = 0; i < this.cfg.steps.length; i++) {
            if (this.cfg.steps[i].price < this.cfg.goods.sell_price) this.cfg.steps[i].price = this.cfg.goods.sell_price
            if (i > 0) {
                if (this.cfg.steps[i].price > this.cfg.steps[i - 1].price) this.cfg.steps[i].price = this.cfg.steps[i - 1].price
                if (this.cfg.steps[i].group_size <= this.cfg.steps[i - 1].group_size) this.cfg.steps[i].group_size = this.cfg.steps[i - 1].group_size + 2
            }
        }
        this.cfg.steps.forEach(s => s.items.sort((a, b) => categories.indexOf(a.category) - categories.indexOf(b.category)))

        if (!this.cfg.props) this.cfg.props = {
            goods_validity_mons: 3,
            group_time_limit_hours: 24,
            buy_bottom_text: this.cfg.type == '价格阶梯团' ? '立即开团(定金)' : "立即开团",
            customer_buy_limit: 1,
            is_only_regular_customer_can_be_leader: false,
            is_only_freshman_can_be_join: false,
            group_info_picture: []
        }
        if (!this.cfg.props.group_info_picture) this.cfg.props.group_info_picture = []
        // 公共属性绑定
        this.activity.status.buy_button_text = this.cfg.props.buy_bottom_text
        this.activity.status.sell_price = this.cfg.goods.sell_price
        const i = this.data.i
        this.activity.status.rules[i] = [
            `每人限购${this.cfg.props.customer_buy_limit}笔`,
            `购买后${this.cfg.props.goods_validity_mons}个月内到店消费有效`,
            `开团后${this.cfg.props.group_time_limit_hours}小时内不成团，视为未成团`
        ]
        if (this.cfg.props.is_only_regular_customer_can_be_leader) this.activity.status.rules[i].push('仅允许老会员开团')
        if (this.cfg.props.is_only_freshman_can_be_join) this.activity.status.rules[i].push('仅允许新客参团')
        watch(this.cfg, c => {
            let totalValue = 0
            for (let j = 0; j < c.steps.length; j++) {
                this.cfg.steps[j].total_value = c.steps[j].items.reduce((n, i) => n + i.value, 0)
                totalValue = this.cfg.steps[j].total_value > totalValue ? this.cfg.steps[j].total_value : totalValue
                // console.log('total_value', this.cfg.goods.total_value)
            }
            setTimeout(() => {
                this.cfg.goods.total_value = totalValue
            }, 10)
            this.cfg.steps.forEach(s => s.items.sort((a, b) => categories.indexOf(a.category) - categories.indexOf(b.category)))
            // this.activity.data.title = this.activity.previewTitle(this.activity.data.titles.find(t => t.title_id == editor.activity?.status.title_id)?.title)
            this.activity.status.buy_button_text = c.props.buy_bottom_text
            this.activity.status.sell_price = c.goods.sell_price
            this.activity.status.rules[i] = [
                `每人限购${c.props.customer_buy_limit}笔`,
                `购买后${c.props.goods_validity_mons}个月内到店消费有效`,
                `开团后${c.props.group_time_limit_hours}小时内不成团，视为未成团`
            ]
            if (c.props.is_only_regular_customer_can_be_leader) this.activity.status.rules[i].push('仅允许老会员开团')
            if (c.props.is_only_freshman_can_be_join) this.activity.status.rules[i].push('仅允许新客参团')
        })
    }
    
    validate(): Error | null {
        // 公共数据
        if (!this.cfg.goods.sell_price) return new Error(this.cfg.type == '价格阶梯团' ? '定金不能为空' : '团购价不能为空')
        if (!this.cfg.goods.stock) return new Error('库存不能为空')
        if (this.cfg.goods.sell_price <= 0) return new Error(this.cfg.type == '价格阶梯团' ? '定金至少0.1元' : '团购价至少0.1元')
        if (this.cfg.goods.stock < 1) return new Error('库存至少为1')
        if (this.cfg.steps.length == 0) return new Error('阶梯不能为空')
        if (this.cfg.type != '普通团' && this.cfg.steps.length < 2) return new Error('阶梯团至少要2个阶梯')
        for (let j = 0; j < this.cfg.steps.length; j++) {
            const s = this.cfg.steps[j]
            if (this.cfg.type == '价格阶梯团' && s.price < this.cfg.goods.sell_price) return new Error('团购价不得高于定金')
            if (j > 0) {
                const l = this.cfg.steps[j - 1]
                if (l.group_size > s.group_size) return new Error('成团人数必须高于上一阶梯')
                if (this.cfg.type == '价格阶梯团' && l.price <= s.price) return new Error('团购价必须逐级递减')
            }
            if (s.items.length == 0) {
                return new Error(`${s.group_size}人团，礼包不能为空`)
            }
            s.items.forEach(i => {
                if (i.title == '') return new Error(`${s.group_size}人团，礼包标题不能为空`)
                if (i.value == 0) return new Error(`${s.group_size}人团，礼包价值不能为0`)
            })
        }
        if (this.cfg.fail_items.length == 0) return new Error('未成团设置不能为空')
        return null
    }

    variable(name: string): string | null {
        switch (name) {
            case '拼团价':
                return this.cfg.goods.sell_price.toString()
            case '拼团类型':
                return this.cfg.type
            case '商品价值':
                return this.cfg.goods.total_value.toString()
        }
        return super.variable(name);
    }
}