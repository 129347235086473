import {DefineComponent} from "@vue/runtime-core";
import {Module} from "@/app/editor/module/index";
import {Activity} from "@/app/editor/activity";
import {IModuleData} from "@/lib/activity";
import {basic} from "@/app/editor/module/basic";
import {sign} from "@/app/editor/module/sign";
import {forward} from "@/app/editor/module/forward";
import {group} from "@/app/editor/module/group";
import {introduction} from "@/app/editor/module/introduction";
import {recommend} from "@/app/editor/module/recommend";
import {shop} from "@/app/editor/module/shop";

export class ModuleManager {
    private v = 0
    private m = ''
    private readonly modules: { [key: number]: { [key: string]: typeof Module } } = {}

    constructor() {
        basic(this.module('basic'))
        forward(this.module('forward'))
        group(this.module('group'))
        introduction(this.module('introduction'))
        recommend(this.module('recommend'))
        shop(this.module('shop'))
        sign(this.module('sign'))
    }

    version(v: number): ModuleManager {
        this.v = v
        return this
    }

    module(m: string): ModuleManager {
        this.m = m
        return this
    }

    reg(m: typeof Module): ModuleManager {
        !this.modules[this.v] ? this.modules[this.v] = {} : null
        this.modules[this.v][this.m] = m
        return this
    }

    gen(a: Activity, m: IModuleData): Module {
        if (this.modules[m.version][m.module])
            return new this.modules[m.version][m.module](a, m, '', '', {} as DefineComponent, {} as DefineComponent)
        else throw new Error(`module "${m.module}" not found`)
    }

}

export const mm = new ModuleManager()