import {reactive} from "vue";
import {Activity} from "@/app/editor/activity";
import {hash, req} from "@/lib/util";
import {IThemeImageValue} from "@/lib/activity";

export interface IImageTask {
    config: IImageConfig
    src: string
    code: number
    loading: boolean
}

export interface IImageConfig {
    key: string
    image_url: string
    image_id: string
    base_image_url: string
    values: { [key: number]: IThemeImageValue }
}

class Queue {
    readonly images: { [key: string]: IImageTask } = reactive({})
    private readonly buffer: IImageTask[] = []
    private readonly waitBuffer: ((ip: IImageTask) => void)[] = []


    send(ip: IImageTask): void {
        if (this.waitBuffer.length > 0) this.waitBuffer.shift()?.(ip)
        else this.buffer.push(ip)
    }

    async receive(): Promise<IImageTask> {
        const ip = this.buffer.shift()
        if (ip) return ip
        else return new Promise<IImageTask>((resolve) => {
            this.waitBuffer.push(resolve)
        })
    }
}

class Engine {
    readonly queue = new Queue()
    readonly images: { [key: string]: IImageTask } = reactive({})
    private activity: Activity | null = null

    constructor() {
        this.serve().then()
    }


    send(cfg: IImageConfig): void {
        if (!(cfg.key in this.images)) {
            this.images[cfg.key] = {
                code: 0,
                config: cfg,
                loading: false,
                src: "https://activity.djhdb.cn/media/image/null.jpg"
            }
        } else {
            this.images[cfg.key].config = cfg
        }
        this.queue.send(this.images[cfg.key])
    }

    bindActivity(a: Activity) {
        this.activity = a
    }

    async serve() {
        for (; ;) {
            const ip = await this.queue.receive()
            await this.loadImage(ip)
        }
    }

    private loadImage(it: IImageTask): Promise<IImageTask> {
        if (!this.activity) return Promise.reject(new Error('No activity found.'))
        if (it.config.image_url) {
            it.src = it.config.image_url
            return Promise.resolve(it)
        }
        const code = this.activity.code() + hash((JSON.stringify(it.config.values) ?? 'null') + it.config.base_image_url)
        // console.log(it.config.key, it.code, code, it.code == code)
        if (it.code == code) return Promise.resolve(it)
        if (!it.config.image_id && !it.config.base_image_url) {
            it.loading = false
            it.src = 'https://activity.djhdb.cn/media/image/null.jpg'
            return Promise.resolve(it)
        }
        it.code = code
        it.loading = true
        return new Promise((resolve, reject) => {
            if (!this.activity) return Promise.reject(new Error('No activity found.'))
            req({
                url: 'editor/image/preview',
                method: 'post',
                data: {
                    ...it.config,
                    activity_id: this.activity.data.activity_id,
                    title: this.activity.data.title,
                    tags: this.activity.data.tags,
                    ...this.activity.genImageTemplateData()
                },
                config: {responseType: 'blob'},
                success: (rs: Blob) => {
                    it.loading = false
                    if (rs.size > 1000) it.src = window.URL.createObjectURL(rs)
                    else it.code = 0
                    resolve(it)
                }, fail: e => reject(e), complete: () => it.loading = false
            })
        })
    }
}

export const engine = new Engine()