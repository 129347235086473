import {IThemeImageConfig, IThemeImageValue} from "@/lib/activity";
import {ITheme} from "@/app/editor/material/theme";
import {ref, UnwrapRef} from "vue";
import {engine, IImageConfig} from "@/app/editor/material/imager";
import emit from "@/lib/emit";

export class ThemeImage {
    public needUpload = false
    public needChangeStyle = ref(false)
    public needSetting = false
    public readonly title: string
    public readonly tip: string
    public version = 0


    constructor(public readonly theme: UnwrapRef<ITheme>, public themeConfig: UnwrapRef<IThemeImageConfig>, public readonly ts: 'banner' | 'poster' | 'share_card') {
        engine.send(this.genImageTaskConfig())
        this.needUpload = !theme.theme_id
        this.needChangeStyle.value = theme.theme_id ? ['poster', 'share_card'].includes(ts) : this.ts == 'poster' && !!themeConfig.values
        if (themeConfig.values) this.needSetting = Object.values(themeConfig.values)?.filter(k => ['text', 'url_image'].includes(k.type)).length > 0
        this.title = ({'banner': '首屏大图', 'share_card': '分享卡片封面', 'poster': '分享海报'} as
            { [key: string]: string })[ts]
        this.tip = ({'banner': '推荐宽度>=720px', 'share_card': '推荐尺寸：720x576', 'poster': '推荐尺寸：720x1280'} as {
            [key: string]: string
        })[ts]
        if (!theme.theme_id && ts == 'poster' && !themeConfig.values) themeConfig.values = {
            0: {
                type: 'applet_poster',
                title: '',
                value: (this.version + 1).toString(),
                max: 0,
                min: 0
            }
        }
        for (const vk in themeConfig.values) {
            if (['applet_poster', 'through_train'].includes(themeConfig.values[vk].type)) this.version = Number(themeConfig.values[vk].value) - 1
        }
        console.log('version', this.version)
    }

    onUploadImg(iu: string) {
        this.themeConfig.image_url = iu
        engine.send(this.genImageTaskConfig())
        this.needChangeStyle.value = this.ts == 'poster'
        console.log('onUploadImg:', this.genImageTaskConfig(), this.needChangeStyle)
    }

    private genImageTaskConfig(version?: number, image_id?: string): IImageConfig {
        // 脱离动态
        const cv = JSON.parse(JSON.stringify(this.themeConfig.values)) as { [p: number]: IThemeImageValue }
        // 修正参数
        if (version) {
            for (const vk in cv) {
                if (['applet_poster', 'through_train'].includes(cv[vk].type)) {
                    cv[vk].value = version.toString()
                }
            }
        }

        return this.ts == 'poster' ? {
            base_image_url: this.themeConfig.image_url,
            image_id: image_id ?? this.themeConfig.image_id,
            image_url: '',
            key: this.ts,
            values: cv
        } : {
            base_image_url: '',
            image_id: image_id ?? this.themeConfig.image_id,
            image_url: this.themeConfig.image_url,
            key: this.ts,
            values: cv
        }
    }

    refresh() {
        engine.send(this.genImageTaskConfig())
    }

    onOptionChange(evt: InputEvent, k: number) {
        this.themeConfig.values[k].value = evt.data ?? ''
        emit.emit('activity.theme.image.value.change', this.themeConfig.values[k])
        this.refresh()
    }

    onValueChange(iv: IThemeImageValue) {
        for (const k in this.themeConfig.values) {
            if (this.themeConfig.values[k].title == iv.title) {
                this.themeConfig.values[k].value = iv.value
                this.refresh()
            }
        }
    }

    stylesImageConfigs(): IImageConfig[] {
        const ics = [] as IImageConfig[]
        Array(this.ts == 'poster' ? 3 : 6).fill(null).map((_, ii) => ii).forEach((_ii) => {
            for (const vk in this.themeConfig.values) {
                if (['applet_poster', 'through_train'].includes(this.themeConfig.values[vk].type))
                    ics.push(this.genImageTaskConfig(_ii + 1, this.themeConfig.values[vk].type == 'applet_poster' ? this.theme.applet_poster_image_template_ids?.[_ii] : undefined))
            }
        })
        ics.forEach((c, k) => {
            c.key = this.ts + k
            engine.send(c)
        })
        return ics
    }

    changeStyle(v: number) {
        for (const vk in this.themeConfig.values) {
            if (['applet_poster', 'through_train'].includes(this.themeConfig.values[vk].type)) this.themeConfig.values[vk].value = (v + 1).toString()
            if (this.themeConfig.values[vk].type == 'applet_poster') this.themeConfig.image_id = this.theme.applet_poster_image_template_ids?.[v]
        }
        this.version = v
        this.refresh()
    }
}
